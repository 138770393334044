import React from 'react'

import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'

import poly_logo from '../../images/logo_poly_en.png'
import step_logo from '../../images/logo_step.png'
import bnc_logo from '../../images/logo_bnc_en.png'
import imc2_logo from '../../images/logo_imc2_en.png'
import logo_cybereco from '../../images/logo_cybereco.png'
import logo_advens from '../../images/logo_advens.png'
import logo_eset from '../../images/logo_eset.png'
import logo_rootme from '../../images/logo_rootme.svg'


import '../../styles/sponsers.sass'
import English_Footer from "../../components/english_footer";

const SponsersPage = () => {
    return (
        <Layout languageSwitchLink="/partners">
            <SEO title="Partenaires" />
            <Banner class_suffix="deep"/>

            <div className="presentation">
                <p>
                    Our partners allows us to carry out all our projects, take part in competitions and develop our club, so we thank them a lot!
                <br/>
                <br/>
                <b> Are you interested in supporting us and contributing to the development of our club? Contact us using <a href={'https://forms.gle/Fok7EFvNSDLVxLbG9'}>this form</a> or on <a href={'https://ca.linkedin.com/company/polycyber'}>LinkedIn</a>.</b>
                </p>

            </div>


            {/* Industrial partners */}
            <h1 className="title">Industrial partners</h1>
            
            <div className="sponsors-container">

                <div className="sponsors-logos">
                    <a href="https://cybereco.ca/">
                        <img src={logo_cybereco} />
                    </a>
                    <h4 className="partner">Cybereco</h4>
                    <p className="text">Founded in 2018, Cybereco brings together an increasing number of members with the common goal of accelerating the development of a world-class workforce and effective technological solutions for a prosperous and secure economy. 
                    <br/>Every year we organize a CTF competition with them!
                    </p>

                </div>

                <div className="sponsors-logos">
                    <a href="https://www.bnc.ca/">
                        <img src={bnc_logo} />
                    </a>
                    <h4 className="partner">National Bank</h4>
                    <p className="text"> A partner for many years, the National Bank is one of Canada's top employers, offering a dynamic environment. 
                    <br/>And cybersecurity in a bank is essential! That's why the NBC is recruiting extensively in the cyber field, from pentesting to cyber defense and governance.</p>
                </div>
                
            </div>

            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="http://www.advens.fr/">
                        <img src={logo_advens} />
                    </a>
                    <h4 className="partner">Advens</h4>
                    <p className="text"> Advens is a French leader, independent and sovereign in the field of cybersecurity. It is present throughout France, as well as in Quebec and Europe.
                    <br/>Its mission is to protect public and private organizations 24 hours a day, 365 days a year. 
                    <br/>This mission guides and drives Advens daily. But that’s not all. Its financial performance allows it to support and accelerate impactful projects through the “Advens for People And Planet” endowment fund.
                    <br/>As you can see, more than just cybersecurity experts, Advens is looking for committed and passionate collaborators.
                    </p>
                </div>
                <div className="sponsors-logos">
                    <a href="https://www.eset.com/ca/">
                        <img src={logo_eset} />
                    </a>
                    <h4 className="partner">ESET</h4>
                    <p className="text"> ESET is a global leader in cybersecurity, recognized for over 30 years for its innovative solutions and its ability to effectively protect businesses and individuals.
                    <br/>Committed to a sustainable future, ESET actively supports educational and social initiatives, reinforcing its dedication beyond technology.
                    <br/>Joining ESET means becoming part of a passionate team where expertise and positive impact come together to tackle today’s and tomorrow’s digital challenges.
                    <br/>Each year, ESET welcomes interns to its research office in Montreal, specializing in malware reverse engineering.
                    </p>
                </div>
            </div>

            <div className="sponsors-container">
                <div className="sponsors-logos">
                    <a href="https://pro.root-me.org/">
                        <img src={logo_rootme} />
                    </a>
                    <h4 className="partner">Root-Me Pro</h4>
                    <p className="text"> Created in 2019, Root-Me PRO is a leading French player in cybersecurity, specializing in training, awareness, and skills assessment in information security.
                    <br/>Emanating from the Root-Me association, known for its community-driven and gamified approach, Root-Me PRO offers tailored solutions for businesses and educational institutions:                  
                    <ul>
                        <li>Awareness: Understanding and preventing cyber threats</li>
                        <li>Training and career guidance: Developing technical skills (web, network, system, SOC, forensic, AI, web3, ...)</li>
                        <li>Educational support: Ensuring skill development that is adaptive, engaging, and sustainable</li>
                        <li>Recruitment: Efficiently identifying and assessing technical expertise</li>
                        <li>Cybersecurity event organization: Uniting and engaging through interactive formats like Escape Games or CTFs (Capture The Flag).</li>
                    </ul>
                    Root-Me PRO’s unique approach is based on its platform designed to make learning enjoyable and accessible, as well as the variety and complementarity of the content offered.
                    </p>
                </div>
            </div>
            
            {/* Academic partners */}
            <h1 className="title">Academic partners</h1>
            <div className="sponsors-container">

                <div className="sponsors-logos">
                    <a href="https://www.polymtl.ca/">
                        <img src={poly_logo}/>
                    </a>
                    <h4 className="partner">Polytechnique Montréal</h4>
                    <p className="text"> Polytechnique Montréal is one of our main partners, and we thank them warmly!
                    <br/>It is thanks to their support that many of our projects are possible, and they bring us all together.
                    </p>
                    <p className="text">Internationally recognized as one of Canada's leading engineering schools, the school offers a wide choice of programs that enable our members to grow and learn. A true model, Polytechnique offers high-level teaching and research activities that address the major issues facing our society, influencing its environment intellectually, economically and socially. </p>
                    <p className="text">If cybersecurity interests you and you're looking for a recognized diploma, Polytechnique offers:
                        <ul>
                            <li>certificates and microprograms in cybersecurity</li>
                            <li>specializations in the computer engineering bachelor's program</li>
                            <li>a cumulative bachelor's degree in cybersecurity</li>
                            <li>master's degrees (professional or research) in cybersecurity</li>
                            <li>and doctorates, if research is your passion.</li>
                        </ul>
                    </p>

                </div>
                
                <div className="sponsors-logos">
                    <a href="https://i-mc2.ca/">
                        <img src={imc2_logo}/>
                    </a>
                    <h4 className="partner">Multidisciplinary Institute for Cybersecurity and Cyber Resilience </h4>
                    <p className="text"> The creation of IMC2 is based on an initial structuring partnership between Polytechnique Montréal, initiator of the project, Université de Montréal and HEC Montréal. </p>
                    <p className="text">It brings together all the resources of Montréal campus to carry out ambitious, innovative and multidisciplinary projects. With numerous professors and researchers involved in cybersecurity, as well as several training courses in the field, Montréal Campus is now a major Canadian player in this sector. Right from the start, the pooling of cyber expertise in technology, human factors, and management places IMC2 as a central actor in the ecosystem.</p>
                </div>
            </div>

            {/*Autres partenaires */}
            <h2 className="title">Student partners</h2>
            <div className="sponsors-container">

                <div className="sponsors-particuliers">
                    <a href="https://step.polymtl.ca/">
                        <img src={step_logo}/>
                    </a>
                    <h4 className="partner">Polytechnique Student Telematics Service (STEP)</h4>
                    <p className="text">We also thank STEP, the Polytechnique committee that helps us maintain our IT infrastructure!</p>
                </div>
            </div>
                <a href="/files/polypwn_plan_commandites.pdf">
                <button className="signup-button">Learn More</button>
            </a>
            <English_Footer></English_Footer>
        </Layout>
    )
}
export default SponsersPage
