import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'

import '../../styles/index.sass'
import logo from '../../images/logo_polycyber.png'

const IndexPage = (props) => {
    return (
        <div className="index-wrapper">
            <Layout languageSwitchLink="/">
                <SEO title="Accueil" />
                <Banner />
                <div className="index-container">
                    <div className="PolyCyber-logo-container">
                        <img src={logo} />
                    </div>
                    <div className="titles-container">
                        <div className="title">
              PolyCyber
                        </div>
                        <div className="subtitle">Cybersecurity community of Polytechnique Montreal.</div>
                        <AniLink fade duration={0.2} to="/about">
                            <button className="join-button">
                guest@polycyber:~ $ <strong>Join</strong>
                            </button>
                        </AniLink>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default IndexPage
