import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'

import '../styles/index.sass'
import logo from '../images/logo_polycyber.png'

const IndexPage = (props) => {
    return (
        <div className="index-wrapper">
            <Layout languageSwitchLink="/en">
                <SEO title="Accueil" />
                <Banner />
                <div className="index-container">
                    <div className="PolyCyber-logo-container">
                        <img src={logo} />
                    </div>
                    <div className="titles-container">
                        <div className="title">
              PolyCyber
                        </div>
                        <div className="subtitle">La communauté de cybersécurité de Polytechnique Montréal.</div>
                        <AniLink fade duration={0.2} to="/about">
                            <button className="join-button">
                guest@polycyber:~ $ <strong>rejoindre</strong>
                            </button>
                        </AniLink>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default IndexPage
