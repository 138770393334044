import React from 'react'

import '../styles/icomoon.css'
import '../styles/socials.sass'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome' 
import {
    faLinkedinIn,
    faYoutube,
    faInstagram,
    faBluesky
} from '@fortawesome/free-brands-svg-icons'


const Socials = () => (
    <div className="socials-container">
        <SocialButton icon={faBluesky} link="https://bsky.app/profile/polycyber.bsky.social" colour="#2d5d83"/>
        <SocialButton icon={faInstagram} link="https://www.instagram.com/polycyber_polymtl/" colour="#E1306C"/>
        <SocialButton icon={faLinkedinIn} link="https://www.linkedin.com/company/polycyber" colour="#0077b5"/>
        <SocialButton icon={faYoutube} link="https://www.youtube.com/@polycyber/" colour="#FF0000"/>
    </div>
)

const SocialButton = ({ icon, link, colour }) => (
    <a href={link} >
        <div style={{ background: colour, color: 'white' }} className="social-button">
            <div className="icon">
                <FontAwesomeIcon icon={icon} />
            </div>
        </div>
    </a>
)

export default Socials
