import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Foot from '../components/footer'

import '../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import { responsive } from '../utils/responsive'

const AboutPage = () => {
    return (<Layout languageSwitchLink="/en/about">
        <SEO title="À propos" />
        <Banner class_suffix="deep" />
        <div className="about-container">
            <div className="about-content">
                <h3>Qui sommes-nous ?</h3>
                <p>
                    PolyCyber est une société technique de Polytechnique Montréal. Depuis 2013, elle œuvre afin de
                    développer l'intérêt et les compétences de notre communauté étudiante pour la cybersécurité.
                </p>
                <h3>Nos missions</h3>
                <ul>
                    <li>Promouvoir les transferts de connaissances</li>
                    <li>Représenter Polytechnique Montréal dans les compétitions de cybersécurité</li>
                    <li>Tisser des liens avec l'industrie</li>
                    <li>Sensibiliser notre communauté aux enjeux liés à la cybersécurité</li>
                </ul>
                <h3>Nos activités</h3>
                <h4>Hacker Nights et aux ateliers techniques</h4>
                <p>
                    Les Hacker Nights sont des événements hebdomadaires où on résout ensemble (ou pas) des défis de
                    cybersécurité. Tous les Lundis de 18h à 21h en M-2103 !
                </p>
                <p>
                    Les ateliers ont pour but d'approfondir des thèmes variés de la cybersécurité, ainsi que d'améliorer
                    les compétences des participant(e)s. Selon les ateliers, le niveau varie de débutant à avancé.
                </p>
                <h4>Compétitions</h4>
                <p>
                    Consultez notre classement sur <a href="https://ctftime.org/team/59057">CTFTime</a>.
                </p>
                <div className="button-group">
                    <a href="https://discord.gg/ZcYnS3GZE6">
                        <button className="signup-button">Rejoins notre Discord</button>
                    </a>
                </div>
                <p>
                    Toute l'année, PolyCyber organise de nombreuses activités telles que des ateliers techniques, des
                    conférences et des compétitions. De plus, nous participons fréquemment à des concours de type
                    Capture the Flag où les participant(e)s doivent résoudre plusieurs défis et trouver une chaîne de
                    caractères (appelée flag) qui atteste de la résolution du défi. Visitez notre blog pour voir les
                    solutionnaires (<i>write-ups</i>) des compétitions auxquelles nous avons participées sur notre{" "}
                    <Link to="/blog">blog</Link>.
                </p>
            </div>
            <div className="about-content">
                <h1>Équipe exécutive</h1>
                <center className="committee-tables">
                    <h4>2024 - 2025</h4>
                    <table style={{fontFamily: 'Source Code Pro'}}>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Rôle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Dora B.</td>
                                <td>Présidente</td>
                            </tr>
                            <tr>
                                <td>Bilal M.</td>
                                <td>VP Training + VP Compétitions</td>
                            </tr>
                            <tr>
                                <td>François M.</td>
                                <td>VP Commandites</td>
                            </tr>
                            <tr>
                                <td>Yoann S. M.</td>
                                <td>VP Comms</td>
                            </tr>
                            <tr>
                                <td>Corentin F.</td>
                                <td>VP Infra</td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>
            <div className="about-content">
                <center className="committee-tables">
                    <h4>2023 - 2024</h4>
                    <table style={{fontFamily: 'Source Code Pro'}}>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Rôle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Étienne P.</td>
                                <td>Président</td>
                            </tr>
                            <tr>
                                <td>Kaïs M.</td>
                                <td>VP Training + VP Compétitions</td>
                            </tr>
                            <tr>
                                <td>François M.</td>
                                <td>VP Commandites</td>
                            </tr>
                            <tr>
                                <td>Yoann S. M.</td>
                                <td>VP Comms</td>
                            </tr>
                            <tr>
                                <td>Mounir L.</td>
                                <td>VP Infra</td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>
            <div className="about-content">
                <div className="about-content">

                    <h1>Rejoins-nous!</h1>
                    <p>
                        En rejoignant l'équipe exécutive ou l'équipe CTF, tu pourras t'impliquer plus activement dans les activités de notre club, que ce soit pour organiser des événements ou participer aux compétitions de cybersécurité.
                    </p>
                    <div className="team-options">
                        <div className="team-option">
                            <h2>Équipe Exécutive</h2>
                            <p>
                                L'équipe exécutive planifie les ateliers techniques, organise des compétitions CTF (Capture-the-Flag), développe les partenariats avec l'industrie, communique les nouveaux événements et bien plus encore!
                            </p>
                            <a href="https://forms.gle/Q5PedtHzsSjAjCLn6">
                                <button className="signup-button">Rejoins notre équipe Exécutive!</button>
                            </a>
                        </div>
                        <div className="team-option">
                            <h2>Équipe CTF</h2>
                            <p>
                                L'équipe CTF participe à des compétitions tout au long de l'année, incluant des événements prestigieux comme <a href="https://nsec.io/">NorthSec</a> et <a href="https://hfctf.ca/">HackFest</a>. Rejoignez-nous pour repousser vos limites dans des défis techniques de cybersécurité! (*Vous devez être étudiant à Polytechnique Montréal.)
                            </p>
                            <a href="https://forms.gle/nRu5TeagsmhXCqFw8">
                                <button className="signup-button">Rejoins notre équipe CTF!</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Foot></Foot>
    </Layout>)
}
export default AboutPage
